#main-wrapper {
    text-align: center;
    color: #777;
    padding-bottom: 21px;
}

#grid-wrapper {
    display: grid;
    justify-content: center;
    align-items: start;
    grid-template-columns: repeat(auto-fill, 500px);
    gap: 30px;
}