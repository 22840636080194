.row {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 3fr;
}

.column {
    text-align: center;
    padding: 5px;
    background-color: #777;
    color: white;
    border-bottom: 1px solid white;
}

.positiveChange {
    color: green;
}

.negativeChange {
    color: red;
}

.noChange {
    color: white;
}