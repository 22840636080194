#background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    z-index: 2;
}

#close {
    position: fixed;
    top: 0;
    right: 10px;
    transform: rotate(45deg);
    z-index: 3;
}

#close h2 {
    color: #777;
    font-size: 50px;
    cursor: pointer;
    margin: 0px;
}

#box {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

#content {
    opacity: 1;
    width: 80vw;
    min-height: 50vh;
    z-index: 2;
}